<template>
  <div></div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {ACT_BRIDGE_SSO_AUTH, TOKEN_KEY} from "@/store/modules/auth/auth";
import {getItem, isSuccess, lengthCheck} from "@/assets/js/util";
import {saveToken} from "@/assets/js/localstorage.service";
import {computed} from "vue";
import {ssoFail} from "@/assets/js/modules/common/common-message";

export default {
  name: 'BridgeSSO',
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const contents = computed(() => route.params.contents);

    const auth = route.query.auth;

    const goContents = items => {
      let isCollect = false;

      if (contents.value === 'survey') {
        // item => 직원번호|차수번호|생성시간(ms)
        if (items && items.length > 1) {
          isCollect = true;
          router.push({name: 'TimetableSurvey', params: {distCrseSn: items[1]}})
          // router.push({name: 'Main'});
        }

        if (!isCollect) {
          alert('잘못된 접급입니다.');
          router.push({name: 'Main'});
        }

        // 신입 행원 설문
        // router.push({name: 'TimetableSurvey'});
      }
    }

    const noSession = () => { alert(ssoFail); }

    const initBridgeSSO = () => {
      if(auth){
        store.dispatch(`auth/${ACT_BRIDGE_SSO_AUTH}`, auth).then(res => {
          if(isSuccess(res) && lengthCheck(res) && res.authToken) {
            saveToken(TOKEN_KEY, res.authToken);
            goContents(getItem(res));
          }
        }).catch(e => {
          console.log(e);
          noSession();
        });
      }else{
        noSession();
      }
    }

    initBridgeSSO();


  }
}
</script>